import { api } from './api';
import { Params } from '../models/datagrid';
import {
  DesignRequest,
  ManufacturingOrder,
  ManufacturingOrderPaginatedResponse,
  PostShipping
} from '../models/manufacturing-order';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment/moment';

const prefix_path = '/manufacturing/';

export const manufacturingOrdersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getManufacturingOrders: builder.query<ManufacturingOrderPaginatedResponse, Params>({
      query: (arg: Params) => {
        const { page, limit, filters, sort } = arg;
        return `${prefix_path}orders?page=${page}&limit=${limit}${filters}${sort}`;
      }
    }),
    patchManufacturingOrder: builder.mutation<ManufacturingOrder, Partial<ManufacturingOrder>>({
      query: ({
        orderNumber,
        ...patch
      }: {
        orderNumber: string;
        patch: Partial<ManufacturingOrder>;
      }) => ({
        url: `${prefix_path}orders/${orderNumber}`,
        method: 'PATCH',
        body: patch
      })
    }),
    deleteManufacturingOrder: builder.mutation<void, { orderNumber: string }>({
      query: ({ orderNumber }: { orderNumber: string }) => ({
        url: `${prefix_path}orders/${orderNumber}`,
        method: 'DELETE'
      })
    }),
    sendModelingDesignRequest: builder.mutation<ManufacturingOrder, Partial<DesignRequest>>({
      query: ({ orderNumber, ...body }: { orderNumber: string }) => ({
        url: `${prefix_path}orders/${orderNumber}/modeling/design`,
        method: 'POST',
        body: body
      })
    }),
    sendOneDesignRequest: builder.mutation<ManufacturingOrder, Partial<DesignRequest>>({
      query: ({ orderNumber, ...body }: { orderNumber: string }) => ({
        url: `${prefix_path}orders/${orderNumber}/modeling/onedesign`,
        method: 'POST',
        body: body
      })
    }),
    sendManufacturingRequest: builder.mutation<ManufacturingOrder, Partial<ManufacturingOrder>>({
      query: ({ orderNumber }: { orderNumber: string }) => ({
        url: `${prefix_path}orders/${orderNumber}/manufacture`,
        method: 'POST',
        body: {}
      })
    }),
    getOneManufacturingOrder: builder.query<ManufacturingOrder, string>({
      query: (orderNumber: string) => ({
        url: `${prefix_path}orders/${orderNumber}`,
        method: 'GET'
      }),
      providesTags: ['OneManufacturingOrder']
    }),
    postShipping: builder.mutation<ManufacturingOrder, PostShipping>({
      query: ({ orderNumber, shipping }) => ({
        url: `${prefix_path}orders/${orderNumber}/shipping`,
        method: 'POST',
        body: shipping
      })
    }),
    addCommentToManufacturingOrder: builder.mutation<
      ManufacturingOrder,
      { orderNumber: string; comment: string }
    >({
      query: ({ orderNumber, comment }: { orderNumber: string; comment: string }) => ({
        url: `${prefix_path}orders/${orderNumber}/comments`,
        method: 'POST',
        body: { comment }
      }),
      invalidatesTags: ['OneManufacturingOrder']
    }),
    setManufacturingOrderControlled: builder.mutation({
      query: ({ orderNumber }: { orderNumber: string }) => ({
        url: `${prefix_path}orders/${orderNumber}/controlled`,
        method: 'POST'
      })
    }),
    setManufacturingOrderDelivered: builder.mutation({
      query: ({ orderNumber, shippingId }: { orderNumber: string; shippingId: number }) => ({
        url: `${prefix_path}orders/${orderNumber}/shipping/${shippingId}/delivered`,
        method: 'POST'
      })
    }),
    analyzeManufacturingOrder: builder.mutation({
      query: ({ orderNumber }: { orderNumber: string }) => ({
        url: `${prefix_path}orders/${orderNumber}/modeling/analyze`,
        method: 'POST'
      })
    })
  })
});

export const exportManufacturingOrders = (
  startDate: Date,
  endDate: Date,
  assignee?: string,
  step?: string
): Promise<AxiosResponse<object>> => {
  const dateFilters = `filter.creationDate=$btw:${moment(startDate).format(
    'YYYY-MM-DDT00:00:00.000'
  )},${moment(endDate).format('YYYY-MM-DDT23:59:59.000')}`;
  const assigneeFilter = assignee ? `&filter.assigneeEmail=${encodeURIComponent(assignee)}` : '';
  const stepFilter = step ? `&filter.step=${encodeURIComponent(step)}` : '';

  return axios.get(`${prefix_path}orders/export?${dateFilters}${assigneeFilter}${stepFilter}`, {
    responseType: 'arraybuffer'
  });
};

export const {
  useLazyGetManufacturingOrdersQuery,
  usePatchManufacturingOrderMutation,
  useSendModelingDesignRequestMutation,
  useSendManufacturingRequestMutation,
  useSendOneDesignRequestMutation,
  useGetOneManufacturingOrderQuery,
  useLazyGetOneManufacturingOrderQuery,
  usePostShippingMutation,
  useSetManufacturingOrderControlledMutation,
  useSetManufacturingOrderDeliveredMutation,
  useAnalyzeManufacturingOrderMutation,
  useAddCommentToManufacturingOrderMutation,
  useDeleteManufacturingOrderMutation
} = manufacturingOrdersApi;
